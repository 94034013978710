// src/components/Footer.js
import React from 'react';

const Footer = () => (
  <footer className="footer">
    <p>© {new Date().getFullYear()} MuseCreator. All rights reserved.</p>
    <a href="https://twitter.com/musecreator" target="_blank" rel="noopener noreferrer">Twitter</a>
    <a href="https://facebook.com/musecreator" target="_blank" rel="noopener noreferrer">Facebook</a>
  </footer>
);

export default Footer;
