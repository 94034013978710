// src/components/About.js
import React from 'react';

const About = () => (
  <div className="about">
    <h2>About MuseCreator</h2>
    <p>MuseCreator offers premium digital products designed to inspire creativity. Discover our curated selection of assets and tools tailored for creators.</p>
  </div>
);

export default About;
