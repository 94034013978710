// src/pages/Home.js
import React from 'react';
import Hero from '../components/Hero';
import ProductGrid from '../components/ProductGrid';
import About from '../components/About';
import Footer from '../components/Footer';
import './Home.scss';

const Home = () => {
return (
<div className="home">
    {/* Hero Section */}
    <Hero />

    {/* Featured Products */}
    <section className="featured-products">
    <h2>Featured Products</h2>
    <ProductGrid />
    </section>

    {/* About Section */}
    <section className="about-section">
    <About />
    </section>

    {/* Footer */}
    <Footer />
</div>
);
};

export default Home;
