// src/components/ProductGrid.js
import React from 'react';
import Product from './Product';

const products = [
  { id: 1, name: "Product One", price: "$19.99", description: "A premium digital asset." },
  { id: 2, name: "Product Two", price: "$24.99", description: "Another top-tier product." },
  { id: 3, name: "Product Three", price: "$29.99", description: "Quality at your fingertips." }
];

const ProductGrid = () => {
  return (
    <div className="product-grid">
      {products.map((product) => (
        <Product key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductGrid;
