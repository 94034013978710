// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
const [isOpen, setIsOpen] = useState(false);

// Toggle menu for mobile view
const toggleMenu = () => {
setIsOpen(!isOpen);
};

return (
<nav className="navbar">
    {/* Logo */}
    <div className="navbar-logo">
    <Link to="/">MuseCreator</Link>
    </div>

    {/* Hamburger Menu Icon for Mobile */}
    <div className="menu-icon" onClick={toggleMenu}>
    <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
    </div>

    {/* Links */}
    <ul className={`navbar-links ${isOpen ? 'show' : ''}`}>
    <li><Link to="/">Home</Link></li>
    <li><Link to="/products">Products</Link></li>
    <li><Link to="/about">About</Link></li>
    </ul>
</nav>
);
};

export default Navbar;
