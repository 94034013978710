// src/components/Product.js
import React from 'react';

const Product = ({ product }) => (
  <div className="product-card">
    <h3>{product.name}</h3>
    <p>{product.description}</p>
    <span className="price">{product.price}</span>
    <button className="btn btn-primary">Buy Now</button>
  </div>
);

export default Product;
