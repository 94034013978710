// src/components/Hero.js
import React from 'react';

const Hero = () => (
  <section className="hero">
    <div className="hero-content">
      <h1>Welcome to MuseCreator</h1>
      <p>Your source for premium digital products.</p>
      <a href="#products" className="btn btn-primary">Explore Products</a>
    </div>
  </section>
);

export default Hero;
